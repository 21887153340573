<template>
    <div class="icatering">
        <!-- banner -->
        <div class="banner">
            <div class="content df-sb-cen">
                <div class="left">
                    <div class="title">{{ data.repast_banner && data.repast_banner.content.list[0].text }}</div>
                    <div class="tip" v-html="data.repast_banner && data.repast_banner.content.list[0].content"></div>
                    <div class="btn" v-if="mobile" @click="goDownload">免费使用i餐饮商户端<i class="el-icon-arrow-right"></i></div>
                    <div class="btn" style="margin-top: 20px" v-if="mobile" @click="goHdDownload">免费使用i餐饮商户端HD<i class="el-icon-arrow-right"></i></div>
                    <div class="btn" v-if="!mobile" @mouseover="showCode" @mouseout="showCode">
                        商戶免費使用<i class="el-icon-arrow-right"></i>
                        <div v-show="codePop" class="popup df" v-if="init.repast_merchant_code && init.repast_merchant_code.content">
                            <div class="download-code">
                                <img :src="init.repast_merchant_code.content" alt="" />
                                <p>i餐飲商戶版</p>
                            </div>
                            <div class="download-code">
                                <img :src="init.repast_merchanthd_code.content" alt="" />
                                <p>i餐飲商戶版HD</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img :src="data.repast_banner && data.repast_banner.content.list[0].image" alt="" />
                </div>
            </div>
        </div>

        <!-- warp-one -->
        <div class="warp warp-one">
            <div class="content">
                <div class="title">{{ data.repast_benefit && data.repast_benefit.content.text }}</div>
                <div class="tip">
                    {{ data.repast_benefit && data.repast_benefit.content.content }}
                </div>
                <ul class="df">
                    <li v-for="(item, index) in data.repast_benefit.content.list" :key="index">
                        <img :src="item.image" alt="" />
                        <div class="sub_title">{{ item.text }}</div>
                        <div class="sub_tip">{{ item.content }}</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- warp-two -->
        <div class="warp warp-two">
            <div class="img-context" v-for="(item, index) in data.repast_advantage.content.list" :key="index">
                <div class="content df-cen-cen">
                    <div class="img">
                        <img :src="item.image" alt="" />
                    </div>
                    <div class="context">
                        <div class="title">{{ item.text }}</div>
                        <div class="tip">{{ item.content }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-three -->
        <div class="warp warp-three">
            <div class="content">
                <div class="title">{{ data.repast_function && data.repast_function.title }}</div>
                <div class="item-box df">
                    <div class="item df" v-for="(item, index) in data.repast_function.content.list" :key="index">
                        <div class="item-icon">
                            <img :src="item.image" alt="" />
                        </div>
                        <div class="item-text">
                            <div class="item-text-title">{{ item.text }}</div>
                            <div class="item-text-tip">{{ item.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-four -->
        <div class="warp warp-four">
            <div class="content">
                <div class="title">{{ data.application_scenarios && data.application_scenarios.title }}</div>
                <div class="tip">{{ data.application_scenarios && data.application_scenarios.content.text }}</div>
                <ul class="df-sb">
                    <li v-for="(item, index) in data.application_scenarios.content.list" :key="index">
                        <img :src="item.image" alt="" />
                        <div class="sub_title">{{ item.text }}</div>
                        <div class="sub_tip">{{ item.content }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- warp-five -->
        <div class="warp warp-five">
            <div class="content">
                <div class="title">{{ data.repast_process && data.repast_process.content.text }}</div>
                <div class="tip">{{ data.repast_process && data.repast_process.content.content }}</div>
                <ul class="df-sb">
                    <li v-for="(item, index) in data.repast_process.content.list" :key="index">
                        <img :src="item.image" alt="" />
                        <div class="sub_title">{{ item.text }}</div>
                        <div class="sub_tip">{{ item.content }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- warp-six -->
        <div class="warp warp-six">
            <div class="content">
                <div class="title">{{ data.pos_system && data.pos_system.title }}</div>
                <img class="img" :src="data.pos_system.content" alt="" />
                <ul class="df-sb">
                    <div>
                        <li v-for="(item, index) in data.pos_system_list.content.list" v-if="index < 4" :key="index">
                            <img :src="item.image" alt="" />
                            <div class="context">
                                <div class="sub_title">{{ item.text }}</div>
                                <div class="sub_tip">{{ item.content }}</div>
                            </div>
                        </li>
                    </div>
                    <div>
                        <li v-for="(item, index) in data.pos_system_list.content.list" v-if="index > 3" :key="index">
                            <img :src="item.image" alt="" />
                            <div class="context">
                                <div class="sub_title">{{ item.text }}</div>
                                <div class="sub_tip">{{ item.content }}</div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>
        </div>
        <!-- warp-seven -->
        <div class="warp warp-seven">
            <div class="content">
                <div class="title">{{ data.system && data.system.title }}</div>
                <ul class="df-sb">
                    <li v-for="(item, index) in data.system.content.list" :key="index">
                        <img :src="item.image" alt="" />
                        <div class="sub_title">{{ item.text }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- warp-eight -->
        <div class="warp warp-eight">
            <div class="content">
                <div class="title">{{ data.payment && data.payment.title }}</div>
                <div class="tip">{{ data.payment && data.payment.content }}</div>
                <ul class="df-sb">
                    <li v-for="(item, index) in data.payment_platform.content" :key="index"><img :src="item" alt="" /></li>
                </ul>
            </div>
        </div>
        <!-- warp-night -->
        <div class="warp warp-night">
            <div class="content">
                <div class="title">{{ init.merchant && init.merchant.content }}</div>
                <ul class="df-cen-cen">
                    <li v-for="(item, index) in init.merchant_list.content" :key="index"><img :src="item" alt="" /></li>
                </ul>
            </div>
        </div>
        <!-- warp-ten -->
        <div class="warp warp-ten">
            <div class="content df-col-cen-cen">
                <div class="title">{{ data.merchant_use && data.merchant_use.title }}</div>
                <div class="tip">{{ data.merchant_use && data.merchant_use.content.list[0].text }}</div>
                <img :src="data.merchant_use && data.merchant_use.content.list[0].image" alt="" />
                <div class="btn" @click="goDownload" @mouseover="showCode" @mouseout="showCode">
                    商戶免費使用<i class="el-icon-arrow-right"></i>
                    <div v-show="codePop" class="popup df" v-if="init.repast_merchant_code && init.repast_merchant_code.content">
                        <div class="download-code">
                            <img :src="init.repast_merchant_code.content" alt="" />
                            <p>i餐飲商戶版</p>
                        </div>
                        <div class="download-code">
                            <img :src="init.repast_merchanthd_code.content" alt="" />
                            <p>i餐飲商戶版HD</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-ele -->
        <div class="warp warp-ele">
            <div class="content">
                <div class="title">{{ data.iCatering && data.iCatering.title }}</div>
                <ul class="df">
                    <li v-for="(item, index) in data.iCatering.content.list" :key="index">
                        <div class="item df-col">
                            <img :src="item.image" alt="" />
                            <div class="sub_title">{{ item.text }}</div>
                            <div class="line"></div>
                            <div class="df-sb" v-for="(items, indexs) in item.list" v-if="items.text2" :key="indexs">
                                <div class="key">{{ items.text1 }}</div>
                                <div class="value">{{ items.text2 }}</div>
                            </div>
                            <div class="sub_tip" v-for="(items, indexs) in item.list" v-if="!items.text2" :key="indexs">{{ items.text1 }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- warp-twe -->
        <div class="warp warp-twe">
            <div class="content">
                <div class="title">{{ data.problems && data.problems.title }}</div>
                <ul>
                    <li class="df-cen-cen" v-for="(item, index) in data.problems.content.list" :key="index">
                        <div class="left">
                            <div class="sub_title">{{ item.text }}</div>
                            <div class="sub_tip" v-html="item.content"></div>
                        </div>
                        <div class="right"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            data: 'app/icateringData',
            init: 'app/initData',
        }),
    },
    data() {
        return {
            codePop: false,
            mobile: (window.innerWidth || document.documentElement.clientWidth || document.body.clientHclientWidtheight) <= 768,
        };
    },
    watch: {},
    created() {
        window.addEventListener('resize', () => this.isMobile());
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => this.isMobile());
    },
    methods: {
        goDownload() {
            if (this.mobile) this.$router.push({ name: 'icatering-download' });
        },
        goHdDownload() {
            if (this.mobile) this.$router.push({ name: 'icatering-hd-download' });
        },
        showCode() {
            if (this.mobile) return;
            this.codePop = !this.codePop;
        },
        isMobile() {
            this.mobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientHclientWidtheight) <= 768;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
    .icatering {
        .btn {
            min-width: 176px;
            width: 176px;
            height: 49px;
            background: #44bcb9;
            border-radius: 4px;
            color: #ffffff;
            line-height: 49px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            position: relative;
            .popup {
                position: absolute;
                background: rgba(255, 244, 211, 0.39);
                left: 200px;
                top: -25px;
                // width: 400px;
                min-height: 200px;
                padding: 16px 25px 0;
                img {
                    width: 100%;
                    margin: 0;
                }
                p {
                    font-size: 16px;
                    color: #333333;
                }
                &::before {
                    content: '';
                    border: 10px solid rgba(255, 244, 211, 0.39);
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: rgba(255, 244, 211, 0.39);
                    position: absolute;
                    left: -20px;
                    top: 40px;
                }
                .download-code {
                    width: 162px;
                    &:nth-child(1) {
                        margin-right: 20px;
                    }
                }
            }
        }
        .banner {
            padding: 100px 0 150px;
            background: #ffc000;
            .left {
                flex: 2;
                .title {
                    font-weight: 600;
                    color: #ffffff;
                    font-size: 50px;

                    font-weight: bold;
                }
                .tip {
                    font-size: 16px;

                    color: #ffffff;
                    margin: 32px 0;
                    p:nth-child(2) {
                        margin-top: 32px;
                    }
                }
            }
            .right {
                flex: 3;
                img {
                    width: 100%;
                }
            }
        }
        .warp {
            padding: 60px 0;
            .title {
                font-size: 32px;

                font-weight: bold;
                margin: 10px 0;
                text-align: center;
            }
            .tip {
                font-size: 16px;

                margin: 10px 0;
                text-align: center;
                width: 50%;
                margin: 0 auto;
            }
        }
        .warp-one {
            ul {
                margin-top: 60px;
                li {
                    text-align: center;
                    img {
                        width: 200px;
                        height: 200px;
                        margin: 20px 0;
                    }
                    .sub_title {
                        margin: 10px 0;
                        font-size: 24px;

                        font-weight: bold;
                    }
                    .sub_tip {
                        margin: 10px 0;
                        font-size: 16px;
                        width: 70%;
                        margin: 0 auto;
                    }
                    &:not(:first-child):not(:last-child) {
                        margin: 0 20px;
                    }
                }
            }
        }
        .warp.warp-two {
            padding: 0 !important;
        }
        .warp-two {
            .img-context {
                padding: 40px;
                .content {
                    width: 950px;
                    .img {
                        flex: 1;
                        img {
                            width: 330px;
                        }
                    }
                    .context {
                        padding: 0 50px;
                        .title {
                            text-align: left !important;

                            font-weight: bold;
                        }
                        .tip {
                            text-align: left !important;
                            width: 100%;
                        }
                    }
                }
                &:nth-child(2n) {
                    background: #ffdf80;
                    .content {
                        flex-direction: row-reverse;
                    }
                }
                &:nth-child(2n + 1) {
                    background: #ffe999;
                    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .warp-three {
            .item-box {
                margin-top: 60px;
                flex-wrap: wrap;
                .item {
                    width: 300px;
                    max-width: 300px;
                    padding: 0 8px;
                    margin-bottom: 60px;
                    .item-icon {
                        margin-right: 16px;
                        img {
                            width: 60px;
                        }
                    }
                    .item-text {
                        &-title {
                            font-size: 24px;
                            margin-bottom: 10px;

                            font-weight: bold;
                        }
                        &-tip {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .warp-four {
            background: #ffe999;
            ul {
                margin-top: 60px;
                li {
                    width: 320px;
                    img {
                        height: 300px;
                        margin: 0 auto;
                        display: block;
                    }
                    .sub_title {
                        font-size: 24px;
                        font-weight: 500;
                        margin: 24px 0 10px;

                        font-weight: bold;
                    }
                    .sub_tip {
                        font-size: 16px;
                    }
                    &:nth-child(2) {
                        margin: 0 120px;
                    }
                }
            }
        }
        .warp-five {
            background: #fafafa;
            ul {
                margin-top: 60px;
                li {
                    text-align: center;
                    img {
                        width: 200px;
                    }
                    .sub_title {
                        font-size: 24px;
                        margin: 60px 0 18px;

                        font-weight: bold;
                    }
                    .sub_tip {
                        font-size: 16px;
                        width: 80%;
                        margin: 0 auto;
                    }
                }
            }
        }
        .warp-six {
            background: #ffe999;
            .img {
                width: 100%;
                margin: 75px 0;
            }
            ul {
                flex-wrap: wrap;
                & > div {
                    flex: 1;
                }
                li {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 40px;
                    padding-right: 40px;
                    img {
                        width: 74px;
                        margin-right: 16px;
                    }
                    .context {
                        .sub_title {
                            font-size: 24px;

                            font-weight: bold;
                        }
                        .sub_tip {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .warp-seven {
            ul {
                margin-top: 60px;
                li {
                    img {
                        width: 200px;
                    }
                    .sub_title {
                        text-align: center;
                        font-size: 24px;
                        margin-top: 32px;

                        font-weight: bold;
                    }
                }
            }
        }
        .warp-eight {
            background: #ffe999;
            .tip {
                width: 100%;
            }
            ul {
                margin-top: 60px;
                li {
                    flex: 1;
                    padding: 0 10px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .warp-night {
            ul {
                margin-top: 60px;
                li {
                    padding: 0 10px;
                    img {
                        width: 80px;
                    }
                }
            }
        }
        .warp-ten {
            background: #ffe999;
            img {
                width: 50%;
                margin: 60px auto;
                display: block;
            }
            .btn {
                background: #ffaa11;
            }
        }
        .warp-ele {
            background: #f9f9f9;
            ul {
                margin-top: 60px;
                li {
                    flex: 1;
                    padding: 50px;
                    min-height: 360px;
                    .item {
                        min-height: 360px;
                        background: #ffffff;
                        border-radius: 16px;
                        padding: 15px;

                        img {
                            width: 60px;
                            display: block;
                            margin: 0 auto;
                        }
                        .sub_title {
                            font-size: 24px;

                            font-weight: bold;
                            color: #ffaa11;
                            text-align: center;
                            margin: 16px 0;
                        }
                        .line {
                            height: 0px;
                            border: 1px solid #eaeaea;
                            margin-bottom: 12px;
                        }
                        .df-sb {
                            margin: 4px 0;
                        }
                        .key {
                            font-size: 16px;

                            color: #404040;
                        }
                        .value {
                            font-size: 16px;

                            color: #ffaa11;
                        }
                        .sub_tip {
                            margin-top: 12px;
                            text-align: center;
                            font-size: 16px;

                            color: #ffaa11;
                        }
                    }
                }
            }
        }
        .warp-twe {
            background: #ffffff;
            .content {
                width: 600px;
                ul {
                    margin-top: 60px;

                    li {
                        padding: 16px 0;
                        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
                        margin: 16px 0;
                    }
                }
                .left {
                    flex: 1;
                    padding-right: 20px;
                    .sub_title {
                        font-size: 24px;

                        font-weight: bold;
                        color: #000000;
                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        margin-bottom: 8px;
                    }
                    .sub_tip {
                        font-size: 16px;

                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        & >>> a {
                            font-size: 12px;
                            text-decoration: underline;
                        }
                    }
                }
                .right {
                    width: 16px;
                    height: 0px;
                    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
                    border: 2px solid #ffaa11;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .icatering {
        .btn {
            min-width: 240px;
            width: 240px;
            height: 49px;
            background: #44bcb9;
            border-radius: 4px;
            color: #ffffff;
            line-height: 49px;
            text-align: center;
            font-size: 18px;

            font-weight: bold;
            position: relative;
            .popup {
                position: absolute;
                background: rgba(255, 244, 211, 0.39);
                left: 200px;
                top: -25px;
                width: 212px;
                min-height: 200px;
                padding: 16px 25px 0;
                img {
                    width: 100%;
                    margin: 0;
                }
                p {
                    font-size: 16px;
                    color: #333333;
                }
                &::before {
                    content: '';
                    border: 10px solid rgba(255, 244, 211, 0.39);
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: rgba(255, 244, 211, 0.39);
                    position: absolute;
                    left: -20px;
                    top: 40px;
                }
            }
        }
        .banner {
            width: 100%;
            padding: 18.3575vw 0 7.7295vw;
            background-color: #ffc000;
            > .content {
                width: 100%;
                .right {
                    width: 100%;
                    margin: 0 auto;
                    height: 57.0048vw;
                    text-align: center;
                    img {
                        width: 90%;
                    }
                }
                .left {
                    width: 100%;
                    padding: 0 4.8309vw;
                    color: #fff;
                    .title {
                        font-size: 5.7971vw;

                        font-weight: bold;
                        text-align: center;
                    }
                    .tip {
                        margin: 7.7295vw 0;

                        font-size: 3.8647vw;
                        p:first-child {
                            margin-bottom: 7.7295vw;
                        }
                    }
                    .btn {
                        width: 42.5121vw;
                        height: 11.8357vw;
                        background-color: #44bcb9;
                        border-radius: 0.9662vw;
                        font-size: 4.3478vw;

                        font-weight: bold;
                        text-align: center;
                        line-height: 11.8357vw;
                        position: relative;
                        margin: 0 auto;
                        border-radius: 0.9662vw;
                        .el-icon-arrow-right {
                            padding-left: 2.4155vw;
                        }
                        .popup {
                            position: absolute;
                            background: rgba(255, 244, 211, 0.39);
                            left: 50%;
                            top: -35.0242vw;
                            transform: translateX(-50%);
                            width: 28.5024vw;
                            height: 30.1932vw;
                            padding: 16px 25px 0;
                            img {
                                width: 100%;
                                margin: 0;
                            }
                            p {
                                font-size: 2.1739vw;
                                color: #333333;
                            }
                            &::before {
                                content: '';
                                border: 2.4155vw solid rgba(255, 244, 211, 0.39);
                                border-top-color: rgba(255, 244, 211, 0.39);
                                border-bottom-color: transparent;
                                border-left-color: transparent;
                                border-right-color: transparent;
                                position: absolute;
                                left: 50%;
                                bottom: -4.8309vw;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }
            > .df-sb-cen {
                display: flex;
                flex-direction: column-reverse;
            }
        }
        .warp {
            padding: 14.4928vw 0;
            width: 100%;
            .content {
                width: 90%;
                .title {
                    font-size: 5.314vw;

                    font-weight: bold;
                    color: #333333;
                    text-align: center;
                    margin-bottom: 4.1063vw;
                }
                .tip {
                    font-size: 3.8647vw;

                    color: #333333;
                    text-align: center;
                }
            }
        }
        .warp-one {
            width: 100%;
            .df {
                flex-direction: column;
                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 14.4928vw;
                    img {
                        width: 48.3092vw;
                        height: 48.3092vw;
                    }
                    .sub_title {
                        font-size: 5.7971vw;

                        font-weight: bold;
                    }
                    .sub_tip {
                        font-size: 3.8647vw;
                        margin-top: 10px;
                        text-align: center;
                    }
                }
            }
        }
        .warp-two {
            .img-context {
                background-color: #ffe999;
                padding: 14.4928vw 4.8309vw;
                .content {
                    flex-direction: column;
                    .img {
                        width: 90%;
                        margin-bottom: 14.4928vw;
                        img {
                            width: 100%;
                        }
                    }
                    .context {
                        .title {
                            font-weight: bold;
                        }
                    }
                }
            }
            .img-context:nth-child(2n) {
                background-color: #ffdf80;
            }
        }
        .warp-three {
            .item-box {
                margin-top: 60px;
                flex-wrap: wrap;
                .item {
                    width: 50%;
                    margin-bottom: 60px;
                    flex-direction: column;
                    .item-icon {
                        img {
                            width: 36%;
                            height: 100%;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    .item-text {
                        text-align: center;
                        &-title {
                            font-size: 24px;
                            margin: 16px 10px;

                            font-weight: bold;
                        }
                        &-tip {
                            font-size: 16px;
                        }
                    }
                    &:nth-child(2n) {
                        padding-left: 2%;
                    }
                    &:nth-child(2n + 1) {
                        padding-right: 2%;
                    }
                }
            }
        }
        .warp-four {
            background: #ffe999;
            ul {
                margin-top: 60px;
                flex-direction: column;
                li {
                    img {
                        width: 80%;
                        margin: 0 auto;
                        display: block;
                    }
                    .sub_title {
                        font-size: 20px;
                        font-weight: 600;
                        margin: 24px 0 10px;

                        font-weight: bold;
                    }
                    .sub_tip {
                        font-size: 16px;
                    }
                    &:nth-child(2) {
                        margin: 60px 0;
                    }
                }
            }
        }
        .warp-five {
            background: #fafafa;
            ul {
                margin-top: 60px;
                flex-direction: column;
                li {
                    text-align: center;
                    margin-bottom: 60px;
                    img {
                        width: 200px;
                    }
                    .sub_title {
                        font-size: 20px;
                        margin: 60px 0 18px;

                        font-weight: bold;
                    }
                    .sub_tip {
                        font-size: 16px;
                        width: 80%;
                        margin: 0 auto;
                    }
                }
            }
        }
        .warp-six {
            background: #ffe999;
            .img {
                width: 100%;
                margin: 75px 0;
            }
            ul {
                flex-wrap: wrap;

                li {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 40px;
                    img {
                        width: 74px;
                        margin-right: 16px;
                    }
                    .context {
                        .sub_title {
                            font-size: 20px;

                            font-weight: bold;
                        }
                        .sub_tip {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .warp-seven {
            ul {
                flex-direction: column;
                li {
                    margin-top: 60px;
                    img {
                        width: 80%;
                        display: block;
                        margin: 0 auto;
                    }
                    .sub_title {
                        text-align: center;
                        font-size: 24px;
                        margin-top: 16px;

                        font-weight: bold;
                    }
                }
            }
        }
        .warp-eight {
            background: #ffe999;
            .tip {
                width: 100%;
            }
            ul {
                margin-top: 60px;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    padding: 0 3%;
                    margin-bottom: 60px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .warp-night {
            ul {
                margin-top: 60px;
                flex-wrap: wrap;

                li {
                    width: 50%;
                    padding: 0 10%;
                    margin-bottom: 60px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .warp-ten {
            background: #ffe999;
            img {
                width: 100%;
                margin: 60px auto;
                display: block;
            }
            .btn {
                background: #ffaa11;
            }
        }
        .warp-ele {
            background: #f9f9f9;
            ul {
                flex-direction: column;
                margin-top: 60px;
                li {
                    flex: 1;
                    padding: 0px 20px;
                    margin-top: 32px;
                    min-height: 360px;
                    .item {
                        min-height: 360px;
                        background: #ffffff;
                        border-radius: 16px;
                        padding: 15px;

                        img {
                            width: 60px;
                            display: block;
                            margin: 0 auto;
                        }
                        .sub_title {
                            font-size: 20px;

                            font-weight: bold;
                            color: #ffaa11;
                            text-align: center;
                            margin: 16px 0;
                        }
                        .line {
                            height: 0px;
                            border: 1px solid #eaeaea;
                            margin-bottom: 12px;
                        }
                        .df-sb {
                            margin: 4px 0;
                        }
                        .key {
                            font-size: 16px;

                            color: #404040;
                        }
                        .value {
                            font-size: 16px;

                            color: #ffaa11;
                        }
                        .sub_tip {
                            margin-top: 12px;
                            text-align: center;
                            font-size: 16px;

                            color: #ffaa11;
                        }
                    }
                }
            }
        }
        .warp-twe {
            background: #ffffff;
            .content {
                ul {
                    margin-top: 60px;

                    li {
                        padding: 16px 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        margin: 16px 0;
                    }
                }
                .left {
                    flex: 1;
                    padding-right: 20px;
                    .sub_title {
                        font-size: 24px;

                        font-weight: bold;
                        color: #000000;
                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        margin-bottom: 8px;
                    }
                    .sub_tip {
                        font-size: 16px;

                        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
                        & >>> a {
                            font-size: 12px;
                            text-decoration: underline;
                        }
                    }
                }
                .right {
                    width: 16px;
                    height: 0px;
                    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
                    border: 2px solid #ffaa11;
                }
            }
        }
    }
}
</style>
